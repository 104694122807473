import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { UtilityService } from '../utility/utility.service';

@Injectable({
  providedIn: 'root'
})
export class GenericService {

  apiUrl: string;

  constructor(private httpClient: HttpClient
    , private utilityService: UtilityService) {
    this.apiUrl = 'https://live.api.hekaya.blueleaftech.in';
    // this.apiUrl = 'https://api.hekaya.blueleaftech.in';
    // this.apiUrl = 'http://localhost:5000';
  }

  getService(serviceUrl: string, isRequiredToken: boolean): Observable<any> {

    let httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    }

    if (isRequiredToken) {
      let accessToken: string = this.utilityService.getAccessToken();
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + accessToken
        })
      };
    }

    let url: string = this.apiUrl + serviceUrl;
    return this.httpClient.get(url, httpOptions);
  }

  deleteService(serviceUrl: string, isRequiredToken: boolean): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    }

    if (isRequiredToken) {
      let accessToken: string = this.utilityService.getAccessToken();
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + accessToken
        })
      };
    }

    let url: string = this.apiUrl + serviceUrl;
    return this.httpClient.delete(url,httpOptions);
  }

  putService(serviceUrl: string, isRequiredToken: boolean): Observable<any> {

    let httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    }

    if (isRequiredToken) {
      let accessToken: string = this.utilityService.getAccessToken();
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + accessToken
        })
      };
    }

    let url: string = this.apiUrl + serviceUrl;
    return this.httpClient.put(url, httpOptions);
  }

  postService(request: any, serviceUrl: string, isRequiredToken: boolean): Observable<any> {
    let url: string = this.apiUrl + serviceUrl;
    let httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    }

    if (isRequiredToken) {
      let accessToken: string = this.utilityService.getAccessToken();
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + accessToken
        })
      };
    }

    return this.httpClient.post(url, request, httpOptions);
  }

  formPostService(request: any, serviceUrl: string, isRequiredToken: boolean): Observable<any> {
    let url: string = this.apiUrl + serviceUrl;
    let httpOptions = {

    }

    if (isRequiredToken) {
      let accessToken: string = this.utilityService.getAccessToken();
      httpOptions = {
        headers: new HttpHeaders({
          'Authorization': 'Bearer ' + accessToken
        })
      };
    }

    return this.httpClient.post(url, request, httpOptions);
  }

}
